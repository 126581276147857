export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Dashboard',
    route: 'dashboardhome-page',
    icon: 'ShieldIcon',
  },

  {
    title: 'Need Help?',
    route: 'help-page',
    icon: 'CoffeeIcon',
  },
  {
    title: 'Critical Severity',
    route: 'criticalseverityresults-page',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'High Severity',
    route: 'highseverityresults-page',
    icon: 'DribbbleIcon',
  },
  {
    title: 'Medium Severity',
    route: 'mediumseverityresults-page',
    icon: 'CpuIcon',
  },
  {
    title: 'Low Severity',
    route: 'lowseverityresults-page',
    icon: 'CrosshairIcon',
  },
  {
    title: 'Vulnerabilities',
    route: 'vulnerabilityresults-page',
    icon: 'ZapIcon',
  },
  {
    title: 'CVE',
    route: 'cveresults-page',
    icon: 'CloudIcon',
  },
  {
    title: 'Misconfiguration',
    route: 'misconfigresults-page',
    icon: 'CloudSnowIcon',
  },
  {
    title: 'Exposure',
    route: 'exposureresults-page',
    icon: 'CloudDrizzleIcon',
  },
  {
    title: 'Cloud AI Scanner',
    route: 'cloudaiscannerresults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'Cloud Exposure',
    route: 'cloudexposureauditorresults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'Web Discovery',
    route: 'cloudwebservicefinderesults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'Asset Capture',
    route: 'assetcaptureresults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'SSL Certs',
    route: 'sslcertificateresults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'Hidden Endpoints',
    route: 'hiddenendpointresults-page',
    icon: 'ActivityIcon',
  },
  {
    title: 'Subdomain',
    route: 'subdomainresults-page',
    icon: 'CloudLightningIcon',
  },
  {
    title: 'Ports',
    route: 'portsresults-page',
    icon: 'CloudRainIcon',
  },
  
  {
    title: 'Risk Score',
    route: 'riskscore-page',
    icon: 'ShieldOffIcon',
  },
  {
    title: 'Severity chart',
    route: 'severitychart-page',
    icon: 'BarChartIcon',
  },
  {
    title: 'Findings chart',
    route: 'findingschart-page',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Scan Progress',
    route: 'scanprogresschart-page',
    icon: 'ActivityIcon',
  },
]
